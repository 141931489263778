import React, { useEffect, useState } from 'react';
import './App.scss';
import Card from './components/platform/card';
import infos from './components/platform/infos';
import { ImageText } from './components/text/imagetext';
import { CardType } from './types';
import bg from './assets/bg-3.png'
import { NewBorder } from './components/border/newborder';
import 'cpchain-icons/cpchain-icons-font.css'
import { couldAirdrop2, isMobile, AirdropResult } from './utils';
import { Dialog } from './components/platform/dialog';
import { ReactComponent as Twitter} from './assets/twitter.svg'
import { ReactComponent as Discord} from './assets/discord.svg'
import { ReactComponent as Telegram} from './assets/telegram.svg'
import { ReactComponent as Github} from './assets/github.svg'

function App() {
  const [currentCard, setCurrentCard] = useState<CardType>()
  const [visible, setVisible] = useState(false)
  const [balanceError, setBalaceError] = useState(['This airdrop has ended', 'Please wait for the next airdrop'])

  const cardItems = infos.map((info) => 
    <Card key={info.name} info={info} currentCard={currentCard} setCurrentCard={setCurrentCard}>
      {info.children}
    </Card>
  )

  const discordLink = process.env.REACT_APP_DISCORD ? process.env.REACT_APP_DISCORD :'https://discord.com/invite/9agsfFJ8gg'
  const telegramLink = process.env.REACT_APP_TELEGRAM ? process.env.REACT_APP_TELEGRAM : 'https://t.me/cpchain_official'
  const twitterLink = process.env.REACT_APP_TWITTER ? process.env.REACT_APP_TWITTER : 'https://t.me/cpchain_official'
  const githubLink = process.env.REACT_APP_TWITTER ? process.env.REACT_APP_TWITTER : 'https://github.com/CPChain'

  useEffect(() => { //TODO
    couldAirdrop2().then(data => {
      if (data['result'] !== AirdropResult.success) {
        setBalaceError(['This airdrop has ended', 'Please wait for the next airdrop'])
        setVisible(true)
      }}).catch(error => {
        setBalaceError(['Can\'t get airdrop balance', 'Please check you internet'])
        setVisible(true)
      })
  },[])

  return (
    <div className={`w-screen h-screen flex flex-col justify-around items-center bg-purple-200 overflow-hidden select-none`}>
      <NewBorder></NewBorder>
      <div className='w-5/6 h-1/3 absolute top-10 flex flex-col justify-start items-start ml-6 lg:ml-0'>
        {!currentCard && <ImageText bg={bg} className='text-2xl md:text-3xl lg:text-5xl xl:test-5xl w-full h-1/3 font-black'>CPChain Airdrop</ImageText>}
        {!currentCard && <p className='-mt-5 text-xs md:text-sm lg:text-base xl:text-2xl 2xl:text-3xl w-full h-2/3 text-sky-800 font-blod'>Your airdrop is ready, at first, please choose one of the following ways ,and then follow the tips to get your airdrop. If you have any question, please read the  
          <a className='font-bold underline mx-2' target="_blank" rel='noreferrer' href='https://medium.com/cpchain-tech/airdrop-faq-8432161dbb58' >FAQ</a>
        </p>}
      </div>
      
      {<div className={'w-screen transition-all duration-500 overflow-hidden flex items-center h-screen ' + (isMobile() ? 'flex-col mt-60 ': 'justify-around h-screen ') + (currentCard === undefined ? 'lg:mt-18':'mt-0')}>
        {cardItems}
      </div>}
      {!currentCard && <div className={'w-full h-1/6 absolute bottom-0 flex justify-around items-center ' + (isMobile() ? 'flex-col py-4': 'flex-row')}>
        <div className='text-white text-xl font-extrabold w-1/4 flex justify-center items-center cursor-pointer' onClick={() => {window.open(`https://cpchain.io`)}}>
          <p>CPChain</p>
        </div>
        <div className='text-white text-xl font-extrabold w-1/4 flex justify-center items-center'>
          <p>info@cpchain.io</p>
        </div>
        <div className={'flex flex-row justify-center ' + (isMobile()? 'w-2/3 gap-6': 'w-1/4 gap-4')}>
          <Twitter className='fill-white h-6 md:h-8 cursor-pointer transition-all duration-500 hover:scale-150' onClick={() => {window.open(`${twitterLink}`)}}></Twitter>
          <Discord className='fill-white h-6 md:h-8 cursor-pointer transition-all duration-500 hover:scale-150' onClick={() => {window.open(`${discordLink}`)}}></Discord>
          <Telegram className='fill-white h-6 md:h-8 cursor-pointer transition-all duration-500 hover:scale-150' onClick={() => {window.open(`${telegramLink}`)}}></Telegram>
          <Github className='fill-white h-6 md:h-8 cursor-pointer transition-all duration-500 hover:scale-150' onClick={() => {window.open(`${githubLink}`)}}></Github>
        </div>
      </div>}
      <Dialog dialogClassName='w-5/6 h-1/3 md:w-2/3 md:h-1/2 lg:w-1/2 lg:h-1/2' bgColor='bg-red-400' btnClassName='w-12 h-12 right-12 top-12' visible={visible} setVisible={setVisible}>
        <div className='w-full h-full flex justify-center flex-col items-center gap-6 lg:gap-10'>
          <p className='w-4/5 h-10 text-xl md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-white font-bold'>{balanceError[0]}</p>
          <p className='w-4/5 h-10 text-xl md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-white font-bold'>{balanceError[1]}</p>
        </div>
      </Dialog>
    </div>
  )
}

export default App;