import { SendBtn } from "../button/sendbtn"
import { isMobile } from "../../utils"
import { CopyText } from "../text/copytext"
import discordGif from "../../assets/gif/discord.gif"
import { Section } from "../common/section"

type Props = {
  textColor: string
  initInviterCode?: string
}

export function Discord({ textColor, initInviterCode }: Props) {
  const partFlex = isMobile() ? 'flex-col gap-10' : 'flex-row gap-10'
  const partSize1 = isMobile() ? 'w-full h-1/3' : 'w-1/2 h-full'
  const partSize2 = isMobile() ? 'w-full h-2/3' : 'w-1/2 h-full'
  const btnSize = isMobile() ? 'w-32 h-8' : 'w-36 h-9 xl:w-52 xl:h-12'
  const discordLink = 'https://discord.com/users/' + (process.env.REACT_APP_DISCORD_ROBOT_ID ? process.env.REACT_APP_DISCORD_ROBOT_ID : "1041201394247553024") + "/"
  const discordServer = process.env.REACT_APP_DISCORD_SERVER ? process.env.REACT_APP_DISCORD_SERVER : "https://discord.com/invite/9agsfFJ8gg"
  const airdropCmd = "!airdrop " + initInviterCode

  return (
    <div className={`transition-all duration-500 flex ${partFlex} justify-around overflow-hidde h-full w-full`}>
      <div className={`flex relative justify-center items-center ${partSize1} bg-black`}>
        <img src={discordGif} alt='img' className={isMobile()?'h-full':'w-full'}></img>
      </div>
      <div className={`flex flex-col justify-between gap-1 ${partSize2}`}>
        <Section title="STEP 1" desc="Join our server in discord:" className="justify-start">
          <div className={`${btnSize} cursor-pointer mb-2 mt-1`} onClick={() => { window.open(`${discordServer}`) }}>
            <SendBtn>
              <p className={`${textColor} font-black text-sm md:text-sm lg-s:text-sm lg:text-base xl:text-xl 2xl:text-2xl`}>
                Join Discord
              </p>
            </SendBtn>
          </div>
        </Section>
        <Section title="STEP 2" desc="Chat with our Airdrop Bot and click the send message:" className="justify-center">
          <div className={`${btnSize} cursor-pointer mb-2 mt-1`} onClick={() => { window.open(`${discordLink}`) }}>
            <SendBtn>
              <p className={`${textColor} font-black text-sm md:text-sm lg-s:text-sm lg:text-base xl:text-xl 2xl:text-2xl`}>
                Airdrop Bot
              </p>
            </SendBtn>
          </div>
        </Section>

        <Section title="STEP 3" desc="Copy and send the command to bot:" className="justify-end">
          <div className="pt-1 lg:pt-2"><CopyText copyValue={airdropCmd} btnClassName="text-xs md:text-sm lg-s:text-sm lg:text-base xl:text-xl"><div className="font-bold text-xs md:text-sm lg-s:text-sm lg:text-base xl:text-xl text-white"> {airdropCmd} </div></CopyText></div>
        </Section>
      </div>
    </div>
  )
}
