import { isMobile } from "../../utils"
import { SendBtn } from "../button/sendbtn"
import { CopyText } from "../text/copytext"
import telegramGif from "../../assets/gif/telegram.gif"
import { Section } from "../common/section"

type Props = {
  textColor: string
  initInviterCode?: string
}

export function Telegram({ textColor, initInviterCode }: Props) {
  const partFlex = isMobile() ? 'flex-col gap-10' : 'flex-row gap-10'
  const partSize1 = isMobile() ? 'w-full h-1/3' : 'w-1/2 h-full'
  const partSize2 = isMobile() ? 'w-full h-2/3' : 'w-1/2 h-full'
  const btnSize = isMobile() ? 'w-32 h-8' : 'w-36 h-9 xl:w-52 xl:h-12'
  const telegramLink = "https://t.me/" + (process.env.REACT_APP_TELEGRAM_ROBOT_USERNAME ? process.env.REACT_APP_TELEGRAM_ROBOT_USERNAME : "cpchain_airdrop_bot") + '/'
  const telegramServer = process.env.REACT_APP_TELEGRAM_SERVER ? process.env.REACT_APP_TELEGRAM_SERVER : "https://t.me/cpchain_official"
  const airdropCmd = "/airdrop " + initInviterCode

  return (
    <div className={`transition-all duration-500 flex ${partFlex} justify-around overflow-hidde h-full w-full`}>
      <div className={`flex relative justify-center items-center ${partSize1} bg-black`}>
        <img src={telegramGif} alt='img' className={isMobile()?"h-full":"w-full"}></img>
      </div>


      <div className={`flex flex-col justify-around gap-1 ${partSize2}`}>

        <Section title="STEP 1" desc="Chat with our Airdrop Bot and click the start:" className="justify-start">
          <div className={`${btnSize} cursor-pointer mb-2 mt-1`} onClick={() => { window.open(`${telegramLink}`) }}>
            <SendBtn>
              <p className={`${textColor} font-black text-sm md:text-sm lg-s:text-sm lg:text-base xl:text-xl 2xl:text-2xl`}>
                Airdrop Bot
              </p>
            </SendBtn>
          </div>
        </Section>

        <Section title="STEP 2" desc="Copy and send the command to bot:" className="justify-center">
          <div className="pt-1 lg:pt-2"><CopyText copyValue={airdropCmd} btnClassName="text-base md:text-sm lg-s:text-sm lg:text-base xl:text-xl"><div className="font-bold text-base md:text-sm lg-s:text-sm lg:text-base xl:text-xl text-white"> {airdropCmd} </div></CopyText></div>
        </Section>
        <Section title="STEP 3" desc="Join our group in telegram:" className="justify-end invisible">
          <div className={`${btnSize} cursor-pointer mb-2 mt-1`} onClick={() => { window.open(`${telegramServer}`) }}>
            <SendBtn>
              <p className={`${textColor} font-black text-sm md:text-sm lg-s:text-sm lg:text-base xl:text-xl 2xl:text-2xl`}>
                Join Telegram
              </p>
            </SendBtn>
          </div>
        </Section>
      </div>
    </div>
  )
}
