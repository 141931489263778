import React from "react";
import { ImageText } from "../text/imagetext";
import bg from "../../assets/bg-3.png"

type SectionProps = {
  title: string
  desc: string
  children: JSX.Element
  className: string
}
export function Section ({ title, desc, children, className }: SectionProps) {
  return (
    <div className={`w-full h-1/3 flex flex-col ${className}`}>
      <ImageText bg={bg} className="text-base md:text-xl lg-s:text-xl lg:text-2xl 2xl:text-3xl font-black">{title}</ImageText>
      <p className="text-white font-bold text-xs md:text-sm lg-s:text-sm lg:text-base xl:text-xl 2xl:text-2xl">{desc}</p>
      {children}
    </div>
  )
}

