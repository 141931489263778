import midImg from '../../assets/popout-mid.png'
import leftImg from '../../assets/popout-left.png'
import rightImg from '../../assets/popout-right.png'

type Props = {
    children?:JSX.Element
    className?:string
}

export function SendBtn({children,className}:Props) {
    return (
        <div className={`flex relative justify-center items-center w-full h-full transition-all duration-500 rounded-full ${className}`}>
            <img src={leftImg} alt='mid' className="h-full"></img>
            <img src={midImg} alt='mid' className="h-full"></img>
            <div className='h-full w-1/2 bg-repeat-x' style={{backgroundImage:`url(${midImg})`}}></div>
            <img src={rightImg} alt='mid' className="h-full"></img>
            <div className='absolute self-center'>{children}</div>
        </div>
    )
}
