import { useEffect, useState } from "react";
import { platformInfo } from "./infos"
import btnBg from "../../assets/bg-2.png"
import {CloseBtn} from "../button/closebtn"
import { CardType } from "../../types";
import { NewBorder } from "../border/newborder";
import { isMobile } from "../../utils";

type Props = {
    info: platformInfo
    currentCard?: CardType
    setCurrentCard: React.Dispatch<React.SetStateAction<CardType>>
    children?: JSX.Element
}

function Card({ info, currentCard, setCurrentCard, children}: Props) {
    const closedSize = isMobile() ? 'w-5/6 h-1/4 flex-row':'w-1/5 h-1/2 flex-col'
    const hiddenSize = isMobile() ? 'w-5/6 h-0':'w-0 h-1/2 flex-col'
    const btnClassName = isMobile() ? 'w-8 h-8 top-6 right-6':'w-16 h-16 top-12 right-12'
    const inputHeight = isMobile() ? 'h-2/3':'h-1/2'
    const logoSize = isMobile() ? 'w-20':'w-40'
    const [size, setSize] = useState(`${closedSize} cursor-pointer`)
    const [inputSize, setInputSize] = useState('w-0 h-0')
    const [btnSize, setBtnSize] = useState('w-0 h-0')


    useEffect(() => {
        if (currentCard === info.name) {
            setSize('w-screen h-screen flex-col')
            setInputSize(`w-5/6 ${inputHeight}`)
            setBtnSize(`${btnClassName}`)
        } else {
            if (!currentCard) {
                setSize(`${closedSize} cursor-pointer`)
            } else {
                setSize(`opacity-0 ${hiddenSize}`)
            }
            setInputSize('w-0 h-0')
            setBtnSize('w-0 h-0')
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCard, info.name]) 

    const setVisible = (vs: string|undefined) => {
        setCurrentCard(vs as CardType)
    }

    return (
     <div className={`transition-all z-10 overflow-hidden duration-500 ease-in-out transform flex ${size} ${info.backgroundColor} relative  justify-around items-center`} onClick={() => {setVisible(info.name) }}>
        <NewBorder fillColor="fill-purple-200" className="-z-10"></NewBorder>
        <img src={info.logo} alt={info.name} className={`${logoSize}`}></img>
        <div className={`${inputSize} transition-all duration-500 overflow-hidden flex justify-center items-center`}>
            {children}
        </div>
        <p className="text-white font-bold text-xl">
            {info.name}
        </p>
        <div className={`${btnSize} absolute rounded-full`} onClick={(e) => {setVisible(undefined); e.stopPropagation()}}>
            <CloseBtn backgroundColor={info.backgroundColor} bg={btnBg}></CloseBtn>
        </div>
      </div>
    )
}

export default Card;