import { CloseBtn } from "../button/closebtn"
import bg2 from "../../assets/bg-2.png"
import { NewBorder } from "../border/newborder"

type Props = {
  dialogClassName?: string
  btnClassName?: string
  children?: JSX.Element
  bgColor: string
  visible: boolean,
  setVisible: any
}



export function Dialog({dialogClassName, btnClassName, children, bgColor, visible, setVisible}:Props) {

    return (
      <div>
      {visible && <div className={`w-screen h-screen absolute top-0 left-0 flex justify-center items-center z-50 opacity-90 bg-white`}>
        <div className={`relative flex ${dialogClassName} ${bgColor} justify-center items-center`}>
          <NewBorder fillColor="fill-white"></NewBorder>
          <div className={`w-8 h-8 top-8 right-8 ${btnClassName} absolute rounded-full`} onClick={(e) => {setVisible(false); e.stopPropagation()}}>
            <CloseBtn backgroundColor={bgColor} bg={bg2}></CloseBtn>
          </div>
          {children}
        </div>
      </div>}
      </div>
    )
  }

