import copy from 'copy-to-clipboard'
import { useState } from 'react'

export type Props = {
  children?: JSX.Element
  btnClassName?: string
  copyValue: string
}

export function CopyText ({children, btnClassName, copyValue}:Props) {
  const [copiedClassName, setCopiedClassName] = useState('border-white text-white hover:border-gray-300 hover:text-gray-300')
  const [icon, setIcon] = useState('icon-copy')
  const copyText = () => {
    copy(copyValue)
    setCopiedClassName('border-green-300 text-green-300')
    setIcon('icon-check')
    setTimeout(() =>{setIcon('icon-copy'); setCopiedClassName('border-white text-white hover:border-gray-300 hover:text-gray-300')}, 3000)
  }

  return (
    <div className='flex flex-row justify-start'>
      <div className={`inline-block rounded-l-xl border-white border-2 shadow-innerxl px-3 py-1`}>
        {children}
      </div>
      <div className={`flex rounded-r-xl border-2 px-4 justify-center items-center cursor-pointer pt-1 ${btnClassName} ${copiedClassName}`} onClick={() => {copyText()}}>
        <i className={`${icon}`} ></i>
      </div>
    </div>
  )

}





