import React, { useEffect, useState, createRef } from "react"
import { SendBtn } from "../button/sendbtn";
import bg from "../../assets/bg-2.png"
import { CloseBtn } from "../button/closebtn";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as Success} from '../../assets/success.svg'
import { isMobile } from "../../utils";
import { ReactComponent as Fail } from '../../assets/failed.svg'
import { ShareBtn } from "../button/sharebtn";
import { ReactComponent as Twitter} from '../../assets/twitter.svg'
import { ReactComponent as Facebook} from '../../assets/facebook.svg'
import { ReactComponent as Wait} from '../../assets/wait.svg'
import { disallowEmail, allowedEmail } from './infos'
import { NewBorder } from "../border/newborder";
import { CopyText } from "../text/copytext";


type Props = {
  textColor: string
  initInviterCode: string
}

export function Email({textColor, initInviterCode}:Props) {
    const account = localStorage.getItem('account')
    const invitationCode = localStorage.getItem('invitation_code')

    const [emailValue, setEmailValue] = useState('');
    const [codeValue, setCodeValue] = useState(initInviterCode)

    const [emailPass, setEmailPass] = useState(false)
    const [emailDisallow, setEmailDisallow] = useState(false) // 禁用大陆邮箱，如果输入大陆邮箱，则为true
    const [inviterCodePass, setInviterCodePass] = useState(true)

    const [btnClassName, setBtnClassName] = useState(['', 'opacity-50'])
    const [sended, setSended] = useState(false)
    
    const [recVisible, setRecVisible] = useState('hidden')
    const recaptchaRef1 = createRef<ReCAPTCHA>()

    const divSize = isMobile() ? 'w-full':'w-5/6 md:w-2/3 lg:w-1/2 xl:w-5/12 2xl:w-1/3'
    const iconSize = isMobile() ? 'w-1/3': 'w-1/6'

    const [result, setResult] = useState([0, 'Please Wait a Sencond'])
    const [timeSleep, setTimeSleep] = useState<number>(0)
    const [whichHidden, setWhichHidden] = useState(['', 'hidden'])
    const resultPageSize = isMobile()?'w-full h-full': 'w-1/2 h-5/6'

    useEffect(() => {
      if (account !=null) {
        setSended(true)
        setResult([1, ''])
      }
    }, [account])

    // 发送邮件的函数
    const sendEmail = (email:string, inviter_code:string, recToken:string) => {
      setResult([0, 'Please Wait a Sencond'])
      fetch(process.env.REACT_APP_AIRDROP_API as string + "airdrop/email-apply/", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          account: email,
          inviter_code: inviter_code,
          recaptcha_token: recToken
        })
      }).then((response) => 
        response.json()
      ).then((data) => {
        setResult([data['status'],data['error']])
        if (data['status'] === 1) {
          localStorage.setItem('account', data['account'])
          localStorage.setItem('invitation_code', data['invitation_code'])
        }
      }
      ).catch(err => setResult([404, 'Internet Error']));
    setTimeSleep(60)
    }

    // 每次发送邮件后需等待60秒才能再次发送
    useEffect(() => {
      if (timeSleep > 0) {
        setTimeout(() => setTimeSleep(timeSleep - 1), 1000)
      }
    }, [timeSleep])

    useEffect(() => {
      if (sended) {
        setWhichHidden(['hidden', ''])
      } else {
        setWhichHidden(['', 'hidden'])
      }
    },[sended])

    const send = () => {
      if (emailPass && inviterCodePass && !emailDisallow) {
        setRecVisible('');
        recaptchaRef1.current?.reset();
      }
    }

    // 点击按钮
    const onChange = (token:string|null) => {
      if (token) {
        try {
          sendEmail(emailValue, codeValue, token)
        } catch (error) {
          console.log('ERROR:', error)
          setResult([0, 'Internet Error'])
        }
        setRecVisible('hidden')
        setSended(true)
      }
    }


    // 监测email 和 邀请码的输入,检测格式
    useEffect(() =>{
      // eslint-disable-next-line no-useless-escape
      if((/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(emailValue))) {
        setEmailPass(true);
        let result = false
        allowedEmail.forEach((e) => {if (emailValue.indexOf(e) !== -1) {
          result = true
        }})
        setEmailDisallow(!result)
        // if (result) {
        //   setEmailDisallow(true)
        // } else {
        //   setEmailDisallow(false)
        // }
      } else {
        setEmailPass(false);
      }
      if((codeValue.length===10 && /[a-zA-Z0-9]{10}/.test(codeValue)) || codeValue === "") {
        setInviterCodePass(true)
      } else {
        setInviterCodePass(false)
      }
    }, [emailValue, codeValue])
    

    // 根据email 和 邀请码的格式 是否通过决定 sendemail 按钮是否可点以及其透明度
    useEffect(() => {
      if(emailPass && inviterCodePass && !emailDisallow && timeSleep===0) {
        setBtnClassName(['hover:scale-110', ''])
      } else {
        setBtnClassName(['cursor-not-allowed', 'opacity-50'])
      }
    }, [emailPass, inviterCodePass, timeSleep, emailDisallow])

    return (
      <div className={`w-full h-full flex justify-center select-none`}>
        {<div className={`w-screen h-screen absolute top-0 left-0 flex justify-center items-center ${recVisible}`}>
          <div className="bg-slate-400 opacity-50 absolute z-30 top-0 left-0 w-screen h-screen" onClick={() => {setRecVisible('hidden')}}></div>
          <div className="z-40">
            <ReCAPTCHA ref={recaptchaRef1} sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string} onChange={onChange}>
            </ReCAPTCHA>
          </div>
        </div>}
        {<div className={`transition-all duration-500 flex flex-col overflow-hidde h-full ${divSize} ${whichHidden[0]}`}>

          <div className="flex flex-row justify-around items-center h-1/6 flex-nowrap">
            <p className="text-white w-1/2 text-2xl font-bold ">Email</p>
            <div className="w-1/2 h-1/2">
              <SendBtn className="hover:scale-105">
                <input className="w-full h-full rounded-lg px-2 focus:outline-none" value={emailValue} onChange={(e) =>{setEmailValue(e.target.value)}}>
                </input>
              </SendBtn>
              {emailValue && !emailPass && !emailDisallow && <p className="text-xs text-red-600 font-semibold px-4"> Email is invalid </p>}
              {emailValue && emailPass && emailDisallow && <p className="text-xs text-red-600"> Sorry, we don’t support this email suffix now. </p>}
            </div>
            <div className={`w-10 h-9 ml-8 md:ml-5 lg:md-3`} onClick={()=>{setEmailValue('')}}>
              {emailValue && <CloseBtn backgroundColor="bg-blue-300" bg={bg}></CloseBtn>}
            </div>
          </div>
          <div className="flex flex-row justify-around items-center h-1/6">
            <p className="text-white w-1/2 text-2xl font-bold">Invitation Code</p>
            <div className="w-1/2 h-1/2">
              <SendBtn className="hover:scale-105">
                <input className="w-full h-full rounded-lg px-2 focus:outline-none" value={codeValue} onChange={(e) =>{setCodeValue(e.target.value)}}>
                </input>
              </SendBtn>
              {!inviterCodePass && <p className="text-xs text-red-600 font-semibold px-4"> Invitation Code is invalid </p>}
            </div>
            <div className={`w-10 h-9 ml-8 md:ml-5 lg:md-3`} onClick={()=>{setCodeValue('')}}>
              {codeValue && <CloseBtn backgroundColor="bg-blue-300" bg={bg}></CloseBtn>}
            </div>
          </div>

          <div className="flex flex-row justify-around items-center mt-32 md:mt-24 w-full h-1/6">
            <div className="flex flex-row w-1/2 h-full cursor-pointer" onClick={() => send()}>
              <SendBtn className={btnClassName[0]}>
                <p className={`${textColor} font-black text-xl select-none ${btnClassName[1]}`}>
                  Send Email {timeSleep===0 ? '': ':' + timeSleep}
                </p>
              </SendBtn>
            </div>
          </div>
        </div>}
        {<div className={`transition-all duration-500 flex flex-col justify-between items-center overflow-hidde h-full w-full ${whichHidden[1]}`}>
          {result[0]===1 && <div className={resultPageSize}><EmailResult invitationCode={invitationCode}></EmailResult></div>}
          {result[0]!==1 && result[0]!==0 && <Fail className={`fill-red-600 ${iconSize}`}></Fail>}
          {result[0]===0 && <Wait className={`fill-white animate-spin ${iconSize}`}></Wait>}
          {result[0] !==1 && <div className="w-full h-1/6 flex justify-center items-center flex-col">
            <p className="text-white text-sm md:text-2xl font-bold">{result[1]}</p>
          </div>}
          <div className={`` + (isMobile()? 'w-full flex h-1/3 justify-between items-center gap-2': 'w-1/2 h-1/6 flex justify-between px-12')}>
            <div className={`flex justify-center cursor-pointer ` + (isMobile()?'w-1/4 ml-10 h-1/2' : 'w-1/5 h-5/6')} onClick={() => {setSended(false); setCodeValue(initInviterCode)}}>
              <SendBtn className='hover:scale-110'>
                <p className={`${textColor} font-black text-2xl`}>
                  Back 
                </p>
              </SendBtn>
            </div>
            <div className={`flex justify-center cursor-pointer ` + (isMobile()?'w-1/4 mr-10 h-1/2' : 'w-1/5 h-5/6')} onClick={() => {setSended(false); setCodeValue(initInviterCode); setEmailValue(account? account:'')}}>
              <SendBtn className={`hover:scale-110`}>
                <p className={`${textColor} font-black text-2xl`}>
                  Resend
                </p>
              </SendBtn>
            </div>
          </div>      
        </div>}
      </div>
    )
}

type ResultProps = {
  invitationCode:string | null
  color?:string
}

export function EmailResult({invitationCode, color}: ResultProps) {
  const invitationUrl = process.env.REACT_APP_API + (invitationCode?invitationCode:'')
  const twitterLink = 'https://twitter.com/share?text=CpchainAirdrop&url=' + invitationUrl
  const facebookLink = 'https://www.facebook.com/sharer/sharer.php?u=' + invitationUrl

  return (
    <div className="w-full h-full relative z-10 bg-white flex flex-col p-10 justify-between">
      <NewBorder fillColor="fill-blue-300" className="-z-10"></NewBorder>
      <div className="w-full flex justify-center items-center"><Success className={`fill-green-400 w-12`}></Success></div>
      <div className="text-blue-400 font-semibold text-xs md:text-base lg:text-base xl:text-xl 2xl:text-2xl font-serif">The Email has been sent successfully, please check your email.</div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-1 lg:gap-2 2xl:gap-3 flex-wrap h-fit items-center">
          <div className="text-blue-400 font-semibold text-xs md:text-xs lg:text-sm xl:text-base 2xl:text-lg">
            Share to your friends:
          </div>
          <div onClick={() => {window.open(`${twitterLink}`)}}>
            <ShareBtn color="text-white" text='Twitter' className="bg-blue-400 w-20 h-6 md:w-24 md:h-7">
              <Twitter className="h-2/3 fill-white"></Twitter>
            </ShareBtn>
          </div>
          <div onClick={() => {window.open(`${facebookLink}`)}}>
            <ShareBtn color="text-white" text='Facebook' className="bg-blue-400 w-24 h-6 md:w-28 md:h-7">
              <Facebook className="h-2/3 fill-white"></Facebook>
            </ShareBtn>
          </div>
        </div>
        <div className="flex flex-row md:gap-1 2xl:gap-3 flex-wrap h-fit items-center">
          <div className="text-blue-400 font-semibold text-xs md:text-xs lg:text-sm xl:text-base 2xl:text-lg">
            Copy your invitation url:
          </div>
          <div className="pt-1 lg:pt-2"><CopyText copyValue={invitationUrl} btnClassName="text-xs md:text-sm lg:text-base xl:text-base 2xl:text-lg text-white bg-blue-400"><div className="font-bold text-xs md:text-sm lg:text-base xl:text-base 2xl:text-lg text-blue-400">{invitationUrl}</div></CopyText></div>
        </div>
      </div>
    </div>
  )
}


