import { ReactComponent as Corner} from '../../assets/images/border-corner.svg'
import { ReactComponent as Horizon} from '../../assets/images/border.svg'
import { ReactComponent as Vert} from '../../assets/images/border-vert.svg'
import React, {createRef} from 'react'
import { Repeat } from './repeat'
import { isMobile } from '../../utils'

type Props = {
  fillColor?: string
  className?: string
}

export function NewBorder ({fillColor, className}:Props) {
  const horizon = createRef<HTMLDivElement>();
  const vert = createRef<HTMLDivElement>();
  const svg = createRef<SVGSVGElement>();
  const horizonCount = 40
  const vertCount = 40
  // const svgLength:number = 245
  // const [horizonCount, setHorizonCount] = useState(1)
  // const [vertCount, setVertCount] = useState(1)
  
  const horizonHeight = isMobile() ? 'h-4' : 'h-8'
  const vertWidth = isMobile() ? 'w-4' : 'w-8'
  const cornerSize = isMobile() ? 'w-16 h-16' : 'w-32 h-32'
  const cornerLocation = isMobile() ? ['top-2 left-2', 'top-2 right-2', 'bottom-2 left-2', 'bottom-2 right-2'] : ['top-4 left-4', 'top-4 right-4', 'bottom-4 left-4', 'bottom-4 right-4']



  // useEffect(() => {
  //   const horizonWidth = horizon.current?.offsetWidth as number
  //   const vertHeight = vert.current?.offsetHeight as number
  //   setHorizonCount(Math.ceil(horizonWidth/svgLength))
  //   setVertCount(Math.ceil(vertHeight/svgLength))
  // },[horizon, vert, svg])


  return (
    <div className={`w-full h-full absolute top-0 left-0 ${className}`}>
      <div className={`w-full ${horizonHeight} absolute top-0 left-0 flex flex-row overflow-hidden`} ref={horizon}>
        <Repeat count={horizonCount}><Horizon className={`fill-white h-full flex-shrink-0 ${fillColor}`} ref={svg}></Horizon></Repeat>
      </div>
      <div className={`${vertWidth} h-full absolute top-0 left-0 overflow-hidden`} ref={vert}>
        <Repeat count={vertCount} className="flex-col"><Vert className={`fill-white h-full flex-shrink-0 ${fillColor}`}></Vert></Repeat>
      </div>
      <div className={`w-full ${horizonHeight} absolute bottom-0 transform rotate-180`}>
        <Repeat count={horizonCount}><Horizon className={`fill-white h-full flex-shrink-0 ${fillColor}`}></Horizon></Repeat>
      </div>
      <div className={`${vertWidth} h-full absolute right-0 transform rotate-180 overflow-hidden`}>
        <Repeat count={vertCount} className="flex-col"><Vert className={`fill-white h-full flex-shrink-0 ${fillColor}`}></Vert></Repeat>
      </div>
      <div className={`${cornerSize} absolute ${cornerLocation[0]}`}>
        <Corner className={`fill-white ${fillColor}`}></Corner>
      </div>
      <div className={`${cornerSize} absolute ${cornerLocation[1]} rotate-90`}>
        <Corner className={`fill-white ${fillColor}`}></Corner>
      </div>
      <div className={`${cornerSize} absolute ${cornerLocation[2]} -rotate-90`}>
        <Corner className={`fill-white ${fillColor}`}></Corner>
      </div>
      <div className={`${cornerSize} absolute ${cornerLocation[3]} rotate-180`}>
        <Corner className={`fill-white ${fillColor}`}></Corner>
      </div>
    </div>
  )
}
