


type Props = {
  className?:string,
  color:string,
  text:string
  children?:JSX.Element
}


export function ShareBtn ({className, color, text, children}:Props) {
  return (
    <div className={`flex flex-row justify-center gap-2 items-center rounded-full cursor-pointer bg-white ${className}`}>
      {children}
      <p className={`${color} font-extrabold text-xs md:text-base`}>{text}</p>
    </div>
  )
}
