// import './style.css'

type Props = {
  children?: string
  bg?: string
  className?: string
}


export function ImageText ({children, bg, className}:Props) {
  return (
    <div className={`${className}`}>
      <span className='bg-clip-text text-transparent' style={{backgroundImage: `url(${bg})`}}>
        {children}
      </span>
    </div>)
} 
