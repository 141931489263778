type Props = {
  count:number
  children:JSX.Element
  className?:string
}



export function Repeat ({count, children, className}:Props) {
  const countList = Array.from(Array(40).keys())
  const repeatItems = countList.map((count) => 
    <div key={count}>
      {children}
    </div>
  )

  return(
    <div className={`w-full h-full flex ${className}`}>
      {repeatItems}
    </div>
  )
}




