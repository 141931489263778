
export function isMobile() {
    let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return flag;
}

export  function couldAirdrop() {
    var res = false
    fetch(process.env.REACT_APP_AIRDROP_BALANCE_API as string, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      }).then((response) => 
        response.json()
      ).then((data) => 
        {
            // console.log(data['result'])
            if (data['result'] === 'airdrop balance is enough') {
                // console.log('----xxx')
                res = true
            }
        }
      ).catch(err => {console.log(err)});

    // console.log(res)
    return res
}

export async function couldAirdrop2() {
    const response = await fetch(process.env.REACT_APP_AIRDROP_API as string + "airdrop/airdrop-balance/", {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }})
    const data = await response.json()
    return data
}

export enum AirdropResult {
    success = 'airdrop balance is enough',
    fail = 'airdrop balance is not enough',
    error = 'get airdrop balance failed'
}

