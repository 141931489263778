import discord from '../../assets/discord-1.png'
import telegram from '../../assets/telegram-2.png'
import email from '../../assets/email-2.png'
import { Email } from './email'
import { Discord } from './discord'
import { Telegram } from './telegram'

export type platformInfo = {
  name: string
  backgroundColor: string
  logo: string
  children?: JSX.Element
}

export const disallowEmail = ['@qq.com', '@163.com', '@126.com', 
  '@sina.com', '@thuha', '@snapmail.cc', 
  '@mailtemp.top', '@trashmail.fr', '@trashmail.se',
  '@my10minutemail.com']

export const allowedEmail = ['@gmail.com', '@hotmail.com', '@yahoo.com']

const initInviterCode = () => { // 写法是否需要改变，设置一个常量保存invitercode
  if (process.env.REACT_APP_API === undefined) {
    return ''
  } else {
    const code = window.location.href.replace(process.env.REACT_APP_API as string, '')
    const res = /^[a-zA-Z0-9]{10}/.exec(code)
    if (res) {
      return res[0]
    } else {
      return ''
    }
  }
}

const emailInfo: platformInfo = { name: 'EMAIL', backgroundColor: 'bg-blue-300', logo: email, children: <Email textColor='text-blue-300' initInviterCode={initInviterCode()} /> }
const telegramInfo: platformInfo = { name: 'TELEGRAM', backgroundColor: 'bg-pink-300', logo: telegram, children: <Telegram textColor='text-pink-300' initInviterCode={initInviterCode()}></Telegram> }
const discordInfo: platformInfo = { name: 'DISCORD', backgroundColor: 'bg-red-300', logo: discord, children: <Discord textColor='text-red-300' initInviterCode={initInviterCode()} /> }
const infos: platformInfo[] = [discordInfo, emailInfo, telegramInfo]

export default infos
