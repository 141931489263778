
type Props = {
    backgroundColor:string
    bg:string
}

export function CloseBtn({backgroundColor,bg}:Props) {
    return (
        <div className={`flex relative cursor-pointer justify-center items-center w-full h-full transition-all duration-500 rounded-full hover:scale-110`} style={{backgroundImage:`url(${bg})`}}>
            <div className={`rounded-full absolute w-5/6 h-5/6 transition-all duration-500 ${backgroundColor} hover:scale-90`}>
            </div>
            <div className="flex relative w-full h-full justify-center items-center transition-all duration-500 hover:rotate-180">
                <div className={`rounded-md absolute w-3/6 h-1/6 transition-all duration-500 bg-black rotate-45`} style={{backgroundImage:`url(${bg})`, backgroundSize: '100% 100%'}}>
                </div>
                <div className={`rounded-md absolute w-3/6 h-1/6 transition-all duration-500 bg-black -rotate-45`} style={{backgroundImage:`url(${bg})`, backgroundSize: '100% 100%'}}>
                </div>
            </div>
        </div>
    )
}
